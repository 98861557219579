import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  const abtest = window.localStorage.getItem("abtest");
  console.log("|||", abtest);

  // Define Ad unit names
  // Original
  let RightColumnUnitName = "RightColumn1";
  let LeftColumnUnitName = "LeftColumnHalfPage";
  let LeaderDesktopInfiniteUnitName = "LeaderDesktopInfinite";
  let MPUCenterRightUnitName = "MPUCenterRight";
  let MPUTopRightUnitName = "MPUTopRight";
  let LeaderBelowTitleUnitName = "Leader_BellowTitle";
  let StickyBannerUnitName = "StickyBannerDesktop";
  let MPUParallaxUnitName = "MPU_Parallax";
  if (window.screen.width < 812) {
    StickyBannerUnitName = "StickyBannerMobile";
  }

  if (abtest === "ab.thm.com.b") {
    RightColumnUnitName = "DESK_RightColumn";
    LeftColumnUnitName = "DESK_LeftColumn";
    LeaderDesktopInfiniteUnitName = "DESK_LeaderDesktopInfinite";
    MPUCenterRightUnitName = "DESK_MPUCenterRight";
    MPUTopRightUnitName = "MOB_MPUTopRight";
    LeaderBelowTitleUnitName = "DESK_LeaderBelowTitle";
    StickyBannerUnitName = "DESK_StickyBannerDesktop";
    MPUParallaxUnitName = "MOB_MPUParallax";
    if (window.screen.width < 812) {
      MPUCenterRightUnitName = "MOB_MPUCenterRight";
      LeaderBelowTitleUnitName = "MOB_LeaderBelowTitle";
      StickyBannerUnitName = "MOB_StickyBanner";
    }
  }

  // Define Ad Unit Pathways
  // Original
  let RightColumnPathway = "RightColumn1";
  let LeftColumnPathway = "LeftColumnHalfPage";
  let LeaderDesktopInfinitePathway = "LeaderDesktopInfinite";
  let MPUCenterRightPathway = "MPUCenterRight";
  let MPUTopRightPathway = "MPUTopRight";
  let LeaderBelowTitlePathway = "Leader_BellowTitle";
  let StickyBannerPathway = "StickyBannerDesktop";
  let MPUParallaxPathway = "MPU_Parallax";
  if (window.screen.width < 812) {
    StickyBannerPathway = "StickyBannerMobile";
  }
  // Domain/device Specific
  if (abtest === "ab.thm.com.b") {
    RightColumnPathway = "THM/DESK_RightColumn";
    LeftColumnPathway = "THM/DESK_LeftColumn";
    LeaderDesktopInfinitePathway = "THM/DESK_LeaderDesktopInfinite";
    MPUCenterRightPathway = "THM/DESK_MPUCenterRight";
    MPUTopRightPathway = "THM/DESK_MPUTopRight";
    LeaderBelowTitlePathway = "THM/DESK_LeaderBelowTitle";
    StickyBannerPathway = "THM/DESK_StickyBannerDesktop";
    if (window.screen.width < 812) {
      MPUTopRightPathway = "THM/MOB_MPUTopRight";
      MPUCenterRightPathway = "THM/MOB_MPUCenterRight";
      LeaderBelowTitlePathway = "THM/MOB_LeaderBelowTitle";
      StickyBannerPathway = "THM/MOB_StickyBannerMobile";
      MPUParallaxPathway = "THM/MOB_MPUParallax";
    }
  }
  // prettier-ignore
  const adUnits = [
    {
      condition: !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_') && !checkUtmSourceStartsWith('fb-'),
      id: 'LeaderBelowTitle',
      name: LeaderBelowTitleUnitName,
      path: `/76730613/${LeaderBelowTitlePathway}`,
      sizes: window.screen.width < 812 ? [[300, 250], [336, 280], [320, 100]] : [[728, 90]],
      bidders: [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'LeaderBelowTitle', environment:'desktop', placement: `${LeaderBelowTitlePathway}` } },
        { bidder: 'adform', params: { mid: 1197176 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: { placementId: '23225103' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786137, size: [728,90] } },
        ////{ bidder: 'justpremium', params: {zone: 117362}, labelAll:['notjp'] },
        { bidder: 'openx', params: { unit: '556539277', delDomain: 'lqr-d.openx.net'} },
        { bidder: 'onemobile',params: {dcn: '8a9690e4017c7cc7c14bcc37ac490023',pos: '8a9699c2017c7cc7bc2bcccab8bc007d',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        ////{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_Leader_bellowTitle' } },
        { bidder: 'sovrn', params:{ tagid: 976812 } },
        { bidder: 'teads', params: { pageId: '139293', placementId: '152848'} },
        { bidder: 'triplelift', params: {inventoryCode: 'Healthy-motherCOM_desktop_Leader_bellowTitle_Prebid'} },
        { bidder: 'sharethrough', params: {pkey: 'gpXFrl6VO3d6YdOA1kuSfCtf'} },
        { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116586 } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: LeaderBelowTitleUnitName, publisherId: '1493285'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } },
      ],
      mobile_bidders: [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'LeaderBelowTitle', environment:'mobile', placement:`${LeaderBelowTitlePathway}` } },
        { bidder: 'adform', params: { mid: 1197180 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: { placementId: '23225173' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786148, size: [300,250] } },
        { bidder: 'ix', params: {siteId: 786148, size: [336,280] } },
        ////{ bidder: 'justpremium', params: {zone: 134836}, labelAll:['notjp'] },
        { bidder: 'onemobile',params: {dcn: '8a9690e4017c7cc7c14bcc358e650021',pos: '8a9699c2017c7cc7bc2bccc39a78007b',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '556539279', delDomain: 'lqr-d.openx.net'} },
        // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_Leader_bellowTitle' } },
        { bidder: 'sovrn', params:{ tagid: 976836 } },
        { bidder: 'sovrn', params:{ tagid: 976837 } },
        { bidder: 'sovrn', params:{ tagid: 976838 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152853'} },
        { bidder: 'triplelift', params: {inventoryCode: 'Healthy-motherCOM_mobile_Leader_bellowTitle_Prebid'} },
          { bidder: 'sharethrough', params: {pkey: 'gykecqILBYG4w5uyTKKFMojt'}},
          { bidder: 'sharethrough', params: {pkey: 'QATqsBISZbd4PHP20cCMnbOW'}},
          { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116591 } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: LeaderBelowTitleUnitName, publisherId: '1493285'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } }      
        ],
    },
    {
      condition: window.screen.width < 812,
      id: 'MPUTopRight',
      name: MPUTopRightUnitName,
      path: `/76730613/${MPUTopRightPathway}`,
      sizes: [[300, 250], [336, 280], [320, 100]],
      mobile_bidders: [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'MPUTopRight', environment:'mobile', placement:`${MPUTopRightPathway}` } },
        { bidder: 'adform', params: { mid: 1197182 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: { placementId: '23225176' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix',  params: { siteId: 786151, size: [300, 250] } },
        { bidder: 'ix',  params: { siteId: 786151, size: [336, 280] } },
        ////{ bidder: 'justpremium', params: {zone: 134836}, labelAll:['notjp'] },
        { bidder: 'onemobile', params: { dcn:'8a9690e4017c7cc7c14bcc358e650021', pos:'8a9690e4017c7cc7c14bccc41f0c007c',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '556539281', delDomain: 'lqr-d.openx.net' } },
        // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_MPUTopRight' } },
        { bidder: 'sovrn', params:{ tagid: 976833 } },
        { bidder: 'sovrn', params:{ tagid: 976834 } },
        { bidder: 'sovrn', params:{ tagid: 976835 } },
        { bidder: 'teads', params: { pageId: '139293', placementId: '152854'} },
        { bidder: 'triplelift', params: {inventoryCode: 'Healthy-motherCOM_mobile_MPUTopRight_Prebid'} },
        { bidder: 'sharethrough', params: {pkey: 'llSuMeCacQVj06sPsqB0GMju'} },
        { bidder: 'sharethrough', params: {pkey: 'RwNxwV7WFFYZ3lNGjxmbVzAk'} },
        { bidder: 'sharethrough', params: {pkey: 'ooHJOC4iMJsNpP5ohDy3ozwh'} },
        { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116594 } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },      
        { bidder: 'taboola', params: { tagId: MPUTopRightUnitName, publisherId: '1493285'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } }
      ],
    },
    {
      condition: window.screen.width > 812 && !checkUtmSourceStartsWith('fb-'),
      id: 'RightColumn',
      name: RightColumnUnitName,
      path: `/76730613/${RightColumnPathway}`,
      sizes: window.screen.width > 1440 ? [[300, 600], [160, 600], [300, 250], [120, 600]] : [[160, 600]],
      bidders: [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'RightColumn', environment:'desktop', placement:`${RightColumnPathway}` } },
        { bidder: 'adform', params: { mid: 1197186 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: {placementId: '23225153'} },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786146, size:[160, 600]} },
        ////{ bidder: 'justpremium', params: {zone: 117362}, labelAll:['notjp'] },
        { bidder: 'onemobile',params: {dcn: '8a9690e4017c7cc7c14bcc37ac490023',pos: '8a9690e4017c7cc7c14bcccb228e0081',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: {unit: '556539278', delDomain: 'lqr-d.openx.net'} },
        // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
        { bidder: 'sovrn', params:{ tagid: 976810 } },
        { bidder: 'sovrn', params:{ tagid: 976811 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152848'} },
        { bidder: 'triplelift', params: { inventoryCode: 'Healthy-motherCOM_desktop_RightColumnHalfPage1_Prebid' } },
        { bidder: 'sharethrough', params: {pkey: '4tmJvK56nQLbnyZe4Rfc1Hgt'}},
        { bidder: 'sharethrough', params: {pkey: 'ZjKYIRbqQTi1NTFtvOYGbAGF'}},
        { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116584 } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: RightColumnUnitName, publisherId: '1493285'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } }
        ],
    },
    {
      condition: window.screen.width > 812 && !checkUtmSourceStartsWith('yahoo_') && !checkUtmSourceStartsWith('fb-'),
      id: 'LeftColumn',
      name: LeftColumnUnitName,
      path: `/76730613/${LeftColumnPathway}`,
      sizes: window.screen.width > 1440 ? [[300, 600], [160, 600], [300, 250], [120, 600]] : [[160, 600]],
      bidders: [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'LeftColumn', environment:'desktop', placement: `${LeftColumnPathway}` } },
        { bidder: 'adform', params: { mid: 1197192 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: {placementId: '23225104'} },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786139, size:[160, 600]} },
        //{ bidder: 'justpremium', params: {zone: 134836, allow: ["sa"]}, labelAll:['notjp'] },
        { bidder: 'onemobile',params: {dcn: '8a9690e4017c7cc7c14bcc37ac490023',pos: '8a9699c2017c7cc7bc2bcccc037b007e',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: {unit: '556539295', delDomain: 'lqr-d.openx.net'} },
        // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
        { bidder: 'sovrn', params:{ tagid: 976813 } },
        { bidder: 'sovrn', params:{ tagid: 976814 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152848'} },
        { bidder: 'triplelift', params: { inventoryCode: 'Healthy-motherCOM_desktop_LeftColumn_Prebid' } },
        { bidder: 'sharethrough', params: {pkey: '1t12eb96AMv4WMmAEryPZ0tI'}},
        { bidder: 'sharethrough', params: {pkey: 'ao6lX1zpu0WXJKICP86HfTnL'}},
        { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116589 } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: LeftColumnUnitName, publisherId: '1493285'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } }    
      ],
    },

    {
      condition: !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      id: 'StickyBanner',
      name: StickyBannerUnitName,
      path: `/76730613/${StickyBannerPathway}`,
      sizes: window.screen.width < 812 ? [[320, 50], [300, 50], [360, 50], [320, 100], [300, 100], [360, 100]] : [[728, 90]],
      bidders: [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'StickyBanner', environment:'desktop', placement:`${StickyBannerPathway}` } },
        { bidder: 'adform', params: { mid: 1197189 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: {placementId: '23225154'} },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786147, size:[728, 90]} },
        ////{ bidder: 'justpremium', params: {zone: 117362}, labelAll:['notjp'] },
        { bidder: 'onemobile',params: {dcn: '8a9690e4017c7cc7c14bcc37ac490023',pos: '8a9694cb017c7cc7c69accccd2db0082',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: {unit: '556539296', delDomain: 'lqr-d.openx.net'} },
        // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
        { bidder: 'sovrn', params:{ tagid: 976809 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152848'} },
        { bidder: 'triplelift', params: { inventoryCode: 'Healthy-motherCOM_desktop_StickyBanner_Prebid' } },
        { bidder: 'sharethrough', params: {pkey: 'O21cdZ3ehNlCL4J0dCu7WP1Y'} },
        { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116585 } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: StickyBannerUnitName, publisherId: '1493285'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } }
      ],
      mobile_bidders: [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'StickyBanner', environment:'mobile', placement:`${StickyBannerPathway}` } },
        { bidder: 'adform', params: { mid: 1197183 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: { placementId: '23225181' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786157, size: [320,50]} },
        { bidder: 'ix', params: {siteId: 786157, size: [300,50]} },
        { bidder: 'ix', params: {siteId: 786157, size: [360,50]} },
        { bidder: 'ix', params: {siteId: 786157, size: [320,100]} },
        { bidder: 'ix', params: {siteId: 786157, size: [300,100]} },
        { bidder: 'ix', params: {siteId: 786157, size: [360,100]} },
        //{ bidder: 'justpremium', params: {zone: 134836, allow: ["ms"]}, labelAll:['notjp'] },
        { bidder: 'onemobile',params: {dcn: '8a9690e4017c7cc7c14bcc358e650021',pos: '8a9690e4017c7cc7c14bccc4b445007d',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '556539282', delDomain: 'lqr-d.openx.net' } },
        // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_StickyBannerMobile' } },
        { bidder: 'sovrn', params:{ tagid: 976830 } },
        { bidder: 'sovrn', params:{ tagid: 976831 } },
        { bidder: 'sovrn', params:{ tagid: 976832 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152848'} },
        { bidder: 'triplelift', params: {inventoryCode: 'Healthy-motherCOM_mobile_StickyBanner_Prebid'} },
          { bidder: 'sharethrough', params: {pkey: 'wHs5HwivBJlrgvytg3pZUJQ6'}},
          { bidder: 'sharethrough', params: {pkey: 'faFhBx82VsdJIOFQJXDt6SPt'}},
          { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116601 } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },      
          { bidder: 'taboola', params: { tagId: StickyBannerUnitName, publisherId: '1493285'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } }
        ],
    },

    {
      condition: window.screen.width < 812,
      id: 'MPUCenterRight',
      name: MPUCenterRightUnitName,
      path: `/76730613/${MPUCenterRightPathway}`,
      sizes: [[300, 250], [320, 100], [336, 280], [1, 1]],
      mobile_bidders: [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'MPUCenterRight', environment:'mobile', placement:`${MPUCenterRightPathway}` } },
        { bidder: 'adform', params: { mid: 1197181 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: { placementId: '23225175' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix',  params: { siteId: 786149, size: [300, 250] } },
        { bidder: 'ix',  params: { siteId: 786149, size: [336, 280] } },
        //{ bidder: 'justpremium', params: {zone: 134836, allow: ["is"]}, labelAll:['notjp'] },
        { bidder: 'onemobile' ,params: { dcn:'8a9690e4017c7cc7c14bcc358e650021', pos:'8a9690e4017c7cc7c14bccc5532a007e',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '556539280', delDomain: 'lqr-d.openx.net' } },
        // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_MPUCenterRight' } },
        { bidder: 'sovrn', params:{ tagid: 976839 } },
        { bidder: 'sovrn', params:{ tagid: 976840 } },
        { bidder: 'sovrn', params:{ tagid: 976841 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152855'} },
        { bidder: 'triplelift', params: {inventoryCode: 'Healthy-motherCOM_mobile_MPUCenterRight_Prebid'} },
          { bidder: 'sharethrough', params: {pkey: '4o0NsJqG7BAeAo2nDchjXUVT'}},
          { bidder: 'sharethrough', params: {pkey: 'm1ejmx3dHok4n8g4x3PIPGmA'}},
          //{ bidder: 'ogury', params: { assetKey: 'OGY-1F23106BD0A9', adUnitId: 'c817d7d0-17e3-013b-0c1f-073529a97c48', skipSizeCheck: true } },
          { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116592 } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: MPUCenterRightUnitName, publisherId: '1493285'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } }
      ],
    },
    {
      condition: window.screen.width > 812,
      id: 'LeaderDesktopInfinite',
      name: LeaderDesktopInfiniteUnitName,
      path: `/76730613/${LeaderDesktopInfinitePathway}`,
      sizes: [[728, 90]],
      bidders: [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'LeaderDesktopInfinite', environment:'desktop', placement:`${LeaderDesktopInfinitePathway}` } },
        { bidder: 'adform', params: { mid: 1197187 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: {placementId: '23225102'} },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786138, size:[728,90]} },
        ////{ bidder: 'justpremium', params: {zone: 117362}, labelAll:['notjp'] },
        { bidder: 'onemobile',params: {dcn: '8a9690e4017c7cc7c14bcc37ac490023',pos: '8a9690e4017c7cc7c14bcccc6b500082',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: {unit: '556539293', delDomain: 'lqr-d.openx.net'} },
        // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
        { bidder: 'sovrn', params:{ tagid: 976808 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152848'} },
        { bidder: 'triplelift', params: {inventoryCode: 'Healthy-motherCOM_desktop_LeaderDesktopInfinite_Prebid'} },
          { bidder: 'sharethrough', params: {pkey: 'QydqN1ybI6ndNnbdj6zegK9V'} },
          { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116588 } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: LeaderDesktopInfiniteUnitName, publisherId: '1493285'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } }
      ],
    },
    {
      condition: false,
      id: 'MPU_Parallax',
      name: MPUParallaxUnitName,
      path: `/76730613/${MPUParallaxPathway}`,
      sizes: [[336, 280], [320, 480], [300, 250], [300, 600], [160, 600], [120 , 600], [320,50]],
      bidders: [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'MPU_Parallax', environment:'mobile', placement:`${MPUParallaxPathway}` } },
                     { bidder: 'adform', params: { mid: 1197193 }, labelAll:['notjp'] },
                     { bidder: 'criteo', params: { networkId: 8147 } },
                     { bidder: 'openx', params: { unit: '556539298', delDomain: 'lqr-d.openx.net' } },
                     { bidder: 'teads', params: {pageId: '139293', placementId: '152848'} },
                     { bidder: 'ix', params: {siteId: 786150, size: [300,250]} },
                     { bidder: 'ix', params: {siteId: 786150, size: [320,480]} },
                     { bidder: 'ix', params: {siteId: 786150, size: [336,280]} },
                     { bidder: 'ix', params: {siteId: 786150, size: [300,600]} },
                     { bidder: 'ix', params: {siteId: 786150, size: [160,600]} },
                     { bidder: 'ix', params: {siteId: 786150, size: [320,50]} },
                     { bidder: 'onemobile',params: {dcn: '8a9690e4017c7cc7c14bcc358e650021',pos: '8a9694cb017c7cc7c69accc5ee21007e',ext: {}}},
                     { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
                     { bidder: 'sovrn', params:{ tagid: 976825 }},
                     { bidder: 'sovrn', params:{ tagid: 976826 }},
                     { bidder: 'sovrn', params:{ tagid: 976827 }},
                     { bidder: 'sovrn', params:{ tagid: 976828 }},
                     { bidder: 'sovrn', params:{ tagid: 976829 }},
                     { bidder: 'triplelift', params: {inventoryCode: 'Healthy-motherCOM_mobile_MPUParallax_Prebid'} },
                     { bidder: 'sharethrough', params: {pkey: 'oniSOcVTpOtZPMvEdmrxeXkZ'} }, //320x480
                    { bidder: 'sharethrough', params: {pkey: 'X0RfQLghKMfETxlotqdwxNro'} }, //160x600
                    { bidder: 'sharethrough', params: {pkey: 'Sfk7XomAH51xJJWuMTvkS97U'} }, //300x250
                    { bidder: 'sharethrough', params: {pkey: 'lUYcahBijp7lAjyh8P3SA8Wb'} }, //300x600
                    { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
                    { bidder: 'taboola', params: { tagId: 'MPU_Parallax', publisherId: '1493285'} },
                    { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
                    { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116593 } },
                    { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } },
                    { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
                    { bidder: 'taboola', params: { tagId: MPUParallaxUnitName, publisherId: '1493285'} },
                    { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
                    { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } }
      ],
    },
  ];

  // prettier-ignore
  if (window.screen.width > 1440) {
    const rcMissingIX = adUnits.find((ad) => ad.id === 'RightColumn');
    const lcMissingIX = adUnits.find((ad) => ad.id === 'LeftColumn');
    // Right
    rcMissingIX.bidders.push({ bidder: 'ix', params: { siteId: 786146, size: [300, 600] } });
    rcMissingIX.bidders.push({ bidder: 'ix', params: { siteId: 786146, size: [300, 250] } });
    rcMissingIX.bidders.push({ bidder: 'sovrn', params:{ tagid: 1103049 } });
    // Left
    lcMissingIX.bidders.push({ bidder: 'ix', params: { siteId: 786139, size: [300, 600] } });
    lcMissingIX.bidders.push({ bidder: 'ix', params: { siteId: 786139, size: [300, 250] } });
    lcMissingIX.bidders.push({ bidder: 'sharethrough', params: {pkey: 'L8RSWZAkWl3ofCuuh0SVTzrT'}});
    lcMissingIX.bidders.push({ bidder: 'sovrn', params:{ tagid: 1103051 } });
  }

  return adUnits;
}
