import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  const onemobileDCN =
    window.screen.width > 420
      ? "8a96945901757509a7551d43776b0342"
      : "8a96945901757509a7551d6362c80344";

  // prettier-ignore
  return [
    {
      condition: !checkUtmSourceStartsWith('') && !checkUtmSourceStartsWith('yahoo_'),
      id: 'LeaderBelowTitle',
      name: 'NEXT_Leader_BellowTitle',
      path: '/76730613/NEXT_Leader_BellowTitle',
      sizes: window.screen.width < 1024 ? [[728, 90]] : [[970, 90]],
      bidders: [
           { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'LeaderBelowTitle', environment:'mobile', placement:'NEXT_Leader_BellowTitle' } },
           { bidder:  'adform', params: { mid: 1197168 }, labelAll:['notjp'] },
           { bidder: 'criteo', params: { networkId: 8147 } },
           { bidder: 'ix', params: {siteId: 786153, size: [300,250] } },
           { bidder: 'ix', params: {siteId: 786153, size: [336,280] } },
           { bidder: 'onemobile',params: {dcn: onemobileDCN, pos: '8a9690e4017c7cc7c14bccb6f5650079',ext: {}} },
           { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
           { bidder: 'openx', params: { unit: '556539289', delDomain: 'lqr-d.openx.net'} },
           { bidder: 'sovrn', params:{ tagid: 976842 } },
           { bidder: 'sovrn', params:{ tagid: 976843 } },
           { bidder: 'sovrn', params:{ tagid: 976844 } },
           { bidder: 'teads', params: {pageId: '139293', placementId: '152850'} },
           { bidder: 'triplelift', params: {inventoryCode: 'Healthy-motherCOM_mobile_NEXT_Leader_belowTitle_Prebid'} },
           { bidder: 'sharethrough', params: {pkey: 'e6aPw83qIhTIWTGAwCLaHCKX'} },
           { bidder: 'sharethrough', params: {pkey: 'HBkLoYVHf3tOZqf5DxrRR3QP'} },
           { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116595 } },
           { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} }
      ],
      mobile_bidders: [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'LeaderBelowTitle', environment:'mobile', placement:`NEXT_Leader_BellowTitle` } },
        { bidder:  'adform', params: { mid: 1197168 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: { placementId: '23225177' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786153, size: [300,250] } },
        { bidder: 'ix', params: {siteId: 786153, size: [336,280] } },
        // ////{ bidder: 'justpremium', params: {zone: 117362}, labelAll:['notjp'] },
        { bidder: 'onemobile',params: {dcn: '8a9690e4017c7cc7c14bcc358e650021',pos: '8a9690e4017c7cc7c14bccb6f5650079',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '556539289', delDomain: 'lqr-d.openx.net'} },
        // // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_Leader_bellowTitle' } },
        { bidder: 'sovrn', params:{ tagid: 976842 } },
        { bidder: 'sovrn', params:{ tagid: 976843 } },
        { bidder: 'sovrn', params:{ tagid: 976844 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152850'} },
        { bidder: 'triplelift', params: {inventoryCode: 'Healthy-motherCOM_mobile_NEXT_Leader_belowTitle_Prebid'} },
        { bidder: 'sharethrough', params: {pkey: 'e6aPw83qIhTIWTGAwCLaHCKX'} },
        { bidder: 'sharethrough', params: {pkey: 'HBkLoYVHf3tOZqf5DxrRR3QP'} },
        { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116595 } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
      ],
    },
    {
      condition: !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      id: 'LeaderBelowTitle_1',
      name: 'NEXT_Leader_BellowTitle',
      path: '/76730613/NEXT_Leader_BellowTitle',
      sizes: window.screen.width < 812 ? [[300, 250], [336, 280], [320, 100]] : [[970, 90]],
      bidders: [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'LeaderBelowTitle', environment:'mobile', placement: 'NEXT_Leader_BellowTitle' } },
        { bidder:  'adform', params: { mid: 1197168 }, labelAll:['notjp'] },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786153, size: [300,250] } },
        { bidder: 'ix', params: {siteId: 786153, size: [336,280] } },
        { bidder: 'onemobile',params: {dcn: onemobileDCN ,pos: '8a9690e4017c7cc7c14bccb6f5650079',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '556539289', delDomain: 'lqr-d.openx.net'} },
        { bidder: 'sovrn', params:{ tagid: 976842 } },
        { bidder: 'sovrn', params:{ tagid: 976843 } },
        { bidder: 'sovrn', params:{ tagid: 976844 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152850'} },
        { bidder: 'triplelift', params: {inventoryCode: 'Healthy-motherCOM_mobile_NEXT_Leader_belowTitle_Prebid'} },
        { bidder: 'sharethrough', params: {pkey: 'e6aPw83qIhTIWTGAwCLaHCKX'} },
        { bidder: 'sharethrough', params: {pkey: 'HBkLoYVHf3tOZqf5DxrRR3QP'} },
        { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116595 } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} }
   ],
      mobile_bidders: [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'LeaderBelowTitle', environment:'mobile', placement:`NEXT_Leader_BellowTitle` } },
        { bidder:  'adform', params: { mid: 1197168 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: { placementId: '23225177' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786153, size: [300,250] } },
        { bidder: 'ix', params: {siteId: 786153, size: [336,280] } },
        // ////{ bidder: 'justpremium', params: {zone: 117362}, labelAll:['notjp'] },
        { bidder: 'onemobile',params: {dcn: '8a9690e4017c7cc7c14bcc358e650021',pos: '8a9690e4017c7cc7c14bccb6f5650079',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '556539289', delDomain: 'lqr-d.openx.net'} },
        // // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_Leader_bellowTitle' } },
        { bidder: 'sovrn', params:{ tagid: 976842 } },
        { bidder: 'sovrn', params:{ tagid: 976843 } },
        { bidder: 'sovrn', params:{ tagid: 976844 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152850'} },
        { bidder: 'triplelift', params: {inventoryCode: 'Healthy-motherCOM_mobile_NEXT_Leader_belowTitle_Prebid'} },
        { bidder: 'sharethrough', params: {pkey: 'e6aPw83qIhTIWTGAwCLaHCKX'} },
        { bidder: 'sharethrough', params: {pkey: 'HBkLoYVHf3tOZqf5DxrRR3QP'} },
        { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116595 } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
      ],
    },
    {
      condition: !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      id: 'LeaderBelowTitle_2',
      name: 'NEXT_Leader_BellowTitle',
      path: '/76730613/NEXT_Leader_BellowTitle',
      sizes: window.screen.width < 812 ? [[300, 250], [336, 280], [320, 100]] : [[970, 90]],
      bidders: [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'LeaderBelowTitle', environment:'mobile', placement: 'NEXT_Leader_BellowTitle'} },
        { bidder:  'adform', params: { mid: 1197168 }, labelAll:['notjp'] },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786153, size: [300,250] } },
        { bidder: 'ix', params: {siteId: 786153, size: [336,280] } },
        { bidder: 'onemobile',params: {dcn: onemobileDCN ,pos: '8a9690e4017c7cc7c14bccb6f5650079',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '556539289', delDomain: 'lqr-d.openx.net'} },
        { bidder: 'sovrn', params:{ tagid: 976842 } },
        { bidder: 'sovrn', params:{ tagid: 976843 } },
        { bidder: 'sovrn', params:{ tagid: 976844 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152850'} },
        { bidder: 'triplelift', params: {inventoryCode: 'Healthy-motherCOM_mobile_NEXT_Leader_belowTitle_Prebid'} },
        { bidder: 'sharethrough', params: {pkey: 'e6aPw83qIhTIWTGAwCLaHCKX'} },
        { bidder: 'sharethrough', params: {pkey: 'HBkLoYVHf3tOZqf5DxrRR3QP'} },
        { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116595 } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} }
   ],
      mobile_bidders: [
     { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'LeaderBelowTitle', environment:'mobile', placement:`NEXT_Leader_BellowTitle` } },
        { bidder:  'adform', params: { mid: 1197168 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: { placementId: '23225177' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786153, size: [300,250] } },
        { bidder: 'ix', params: {siteId: 786153, size: [336,280] } },
        // ////{ bidder: 'justpremium', params: {zone: 117362}, labelAll:['notjp'] },
        { bidder: 'onemobile',params: {dcn: '8a9690e4017c7cc7c14bcc358e650021',pos: '8a9690e4017c7cc7c14bccb6f5650079',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '556539289', delDomain: 'lqr-d.openx.net'} },
        // // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_Leader_bellowTitle' } },
        { bidder: 'sovrn', params:{ tagid: 976842 } },
        { bidder: 'sovrn', params:{ tagid: 976843 } },
        { bidder: 'sovrn', params:{ tagid: 976844 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152850'} },
        { bidder: 'triplelift', params: {inventoryCode: 'Healthy-motherCOM_mobile_NEXT_Leader_belowTitle_Prebid'} },
        { bidder: 'sharethrough', params: {pkey: 'e6aPw83qIhTIWTGAwCLaHCKX'} },
        { bidder: 'sharethrough', params: {pkey: 'HBkLoYVHf3tOZqf5DxrRR3QP'} },
        { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116595 } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
      ],
    },
    {
      condition: !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      id: 'LeaderBelowTitle_4',
      name: 'NEXT_Leader_BellowTitle',
      path: '/76730613/NEXT_Leader_BellowTitle',
      sizes: window.screen.width < 812 ? [[300, 250], [336, 280], [320, 100]] : [[970, 90]],
      bidders: [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'LeaderBelowTitle', environment:'mobile', placement: 'NEXT_Leader_BellowTitle' } },
        { bidder:  'adform', params: { mid: 1197168 }, labelAll:['notjp'] },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786153, size: [300,250] } },
        { bidder: 'ix', params: {siteId: 786153, size: [336,280] } },
        { bidder: 'onemobile',params: {dcn: onemobileDCN ,pos: '8a9690e4017c7cc7c14bccb6f5650079',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '556539289', delDomain: 'lqr-d.openx.net'} },
        { bidder: 'sovrn', params:{ tagid: 976842 } },
        { bidder: 'sovrn', params:{ tagid: 976843 } },
        { bidder: 'sovrn', params:{ tagid: 976844 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152850'} },
        { bidder: 'triplelift', params: {inventoryCode: 'Healthy-motherCOM_mobile_NEXT_Leader_belowTitle_Prebid'} },
        { bidder: 'sharethrough', params: {pkey: 'e6aPw83qIhTIWTGAwCLaHCKX'} },
        { bidder: 'sharethrough', params: {pkey: 'HBkLoYVHf3tOZqf5DxrRR3QP'} },
        { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116595 } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} }
   ],
      mobile_bidders: [
       { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'LeaderBelowTitle', environment:'mobile', placement:`NEXT_Leader_BellowTitle` } },
        { bidder:  'adform', params: { mid: 1197168 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: { placementId: '23225177' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786153, size: [300,250] } },
        { bidder: 'ix', params: {siteId: 786153, size: [336,280] } },
        // ////{ bidder: 'justpremium', params: {zone: 117362}, labelAll:['notjp'] },
        { bidder: 'onemobile',params: {dcn: '8a9690e4017c7cc7c14bcc358e650021',pos: '8a9690e4017c7cc7c14bccb6f5650079',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '556539289', delDomain: 'lqr-d.openx.net'} },
        // // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_Leader_bellowTitle' } },
        { bidder: 'sovrn', params:{ tagid: 976842 } },
        { bidder: 'sovrn', params:{ tagid: 976843 } },
        { bidder: 'sovrn', params:{ tagid: 976844 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152850'} },
        { bidder: 'triplelift', params: {inventoryCode: 'Healthy-motherCOM_mobile_NEXT_Leader_belowTitle_Prebid'} },
        { bidder: 'sharethrough', params: {pkey: 'e6aPw83qIhTIWTGAwCLaHCKX'} },
        { bidder: 'sharethrough', params: {pkey: 'HBkLoYVHf3tOZqf5DxrRR3QP'} },
        { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116595 } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
      ],
    },
    {
      condition: window.screen.width > 812,
      id: 'RightColumn',
      name: 'NEXT_RightColumnHalfPage1',
      path: '/76730613/NEXT_RightColumnHalfPage1',
      sizes: window.screen.width > 1440 ? [[300, 600]] : [[160, 600]],
      bidders: [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'RightColumn', environment:'desktop', placement: 'NEXT_RightColumnHalfPage1'} },
        { bidder: 'adform', params: { mid: 1197185 }, labelAll:['notjp'] },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786144, size:[160, 600]} },
        { bidder: 'ix', params: {siteId: 786144, size:[300, 600]} }, // adding this bid outside (search for: adding larger IX ads here)
        { bidder: 'onemobile',params: {dcn: onemobileDCN ,pos: '8a9690e4017c7cc7c14bccc78b0e0080',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: {unit: '556539288', delDomain: 'lqr-d.openx.net'} },
        { bidder: 'sovrn', params:{ tagid: 976815 } },
        { bidder: 'sovrn', params:{ tagid: 976816 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152848'} },
        { bidder: 'triplelift', params: { inventoryCode: 'Healthy-motherCOM_desktop_NEXT_RightColumnHalfPage1_Prebid' } },
        { bidder: 'sharethrough', params: {pkey: 'M8VaAKXHUdCPoc9Bl01taVih'}},
        { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116582 } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
      ],
    },
  ];
}
