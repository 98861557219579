import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  // prettier-ignore
  return [
    {
      "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      "id": 'LeaderBelowTitle',
      "name": 'NEXT_Leader_BellowTitle',
      "path": '/76730613/NEXT_Leader_BellowTitle',
      "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[728, 90]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'LeaderBelowTitle', environment:'desktop', placement: 'NEXT_Leader_BellowTitle' } },
        { bidder: 'adform', params: { mid: 1197163 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: { placementId: '23225124' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786140, size: [728,90] } },
        ////{ bidder: 'justpremium', params: {zone: 117362}, labelAll:['notjp'] },
        { bidder: 'openx', params: { unit: '556539284', delDomain: 'lqr-d.openx.net'} },
        { bidder: 'onemobile',params: {dcn: '8a9690e4017c7cc7c14bcc37ac490023',pos: '8a9694cb017c7cc7c69accc6a375007f',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        ////{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_Leader_bellowTitle' } },
        { bidder: 'sovrn', params:{ tagid: 976823 } },
        { bidder: 'teads', params: { pageId: '139293', placementId: '152848'} },
        { bidder: 'triplelift', params: { inventoryCode: 'Healthy-motherCOM_desktop_NEXT_Leader_bellowTitle_Prebid' } },
          { bidder: 'sharethrough', params: {pkey: 'dZKTPCl55zGgiMUVi4V8bqqo'} },
          { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116575 } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: 'NEXT_Leader_BellowTitle', publisherId: '1493285'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } }
      ],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'LeaderBelowTitle', environment:'mobile', placement:'NEXT_Leader_BellowTitle' } },
        { bidder:  'adform', params: { mid: 1197168 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: { placementId: '23225177' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786153, size: [300,250] } },
        { bidder: 'ix', params: {siteId: 786153, size: [336,280] } },
        // ////{ bidder: 'justpremium', params: {zone: 117362}, labelAll:['notjp'] },
        { bidder: 'onemobile',params: {dcn: '8a9690e4017c7cc7c14bcc358e650021',pos: '8a9690e4017c7cc7c14bccb6f5650079',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '556539289', delDomain: 'lqr-d.openx.net'} },
        // // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_Leader_bellowTitle' } },
        { bidder: 'sovrn', params:{ tagid: 976842 } },
        { bidder: 'sovrn', params:{ tagid: 976843 } },
        { bidder: 'sovrn', params:{ tagid: 976844 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152850'} },
        { bidder: 'triplelift', params: {inventoryCode: 'Healthy-motherCOM_mobile_NEXT_Leader_belowTitle_Prebid'} },
        { bidder: 'sharethrough', params: {pkey: 'e6aPw83qIhTIWTGAwCLaHCKX'} },
        { bidder: 'sharethrough', params: {pkey: 'HBkLoYVHf3tOZqf5DxrRR3QP'} },
        { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116595 } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} }, 
        { bidder: 'taboola', params: { tagId: 'NEXT_Leader_BellowTitle', publisherId: '1493285'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } }     
      ]
    },
    {
      "condition": window.screen.width < 812,
      "id": "MPUTopRight",
      "name": "NEXT_MPUTopRight",
      "path": `/76730613/NEXT_MPUTopRight`,
      "sizes": [[300, 250],[336,280],[320,100]],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'MPUTopRight', environment:'mobile', placement: 'NEXT_MPUTopRight' } },
        { bidder: 'adform', params: { mid: 1197170 }, labelAll:['notjp'] },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix',  params: { siteId: 786155, size: [300, 250] } },
        { bidder: 'ix',  params: { siteId: 786155, size: [336, 280] } },
        { bidder: 'onemobile', params: { dcn:'8a9690e4017c7cc7c14bcc358e650021', pos:'8a9690e4017c7cc7c14bccc02030007a',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '556539291', delDomain: 'lqr-d.openx.net' } },
        { bidder: 'sovrn', params:{ tagid: 976847 } },
        { bidder: 'sovrn', params:{ tagid: 976848 } },
        { bidder: 'sovrn', params:{ tagid: 976849 } },
        { bidder: 'teads', params: { pageId: '139293', placementId: '152851'} },
        { bidder: 'triplelift', params: {inventoryCode: 'Healthy-motherCOM_mobile_NEXT_MPUTopRight_Prebid'} },
        { bidder: 'sharethrough', params: {pkey: 'MFFVHRHETtQJcwpE3X8pJjJu'}},
        { bidder: 'sharethrough', params: {pkey: 'DywyEPUnhopqYnzErcZuKLXg'}},
        { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116599 } },
        { bidder: 'onemobile', params: { pos: '8a9690e4017c7cc7c14bccc02030007a', dcn: '8a9690e4017c7cc7c14bcc358e650021'} },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: "NEXT_MPUTopRight", publisherId: '1493285'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } }
      ]
    },
    {
      "condition": window.screen.width > 812 && !checkUtmSourceStartsWith('fb-'),
      "id": "RightColumn",
      "name": "NEXT_RightColumnHalfPage1",
      "path": `/76730613/NEXT_RightColumnHalfPage1`,
      "sizes": window.screen.width > 1440 ? [[300, 600], [160, 600], [1,1], [300, 250]] : [[160, 600]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'RightColumn', environment:'desktop', placement: 'NEXT_RightColumnHalfPage1' } },
        { bidder: 'adform', params: { mid: 1197185 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: {placementId: '23225151'} },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786144, size:[160, 600]} },
        ////{ bidder: 'justpremium', params: {zone: 117362}, labelAll:['notjp'] },
        { bidder: 'onemobile',params: {dcn: '8a9690e4017c7cc7c14bcc37ac490023',pos: '8a9690e4017c7cc7c14bccc78b0e0080',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: {unit: '556539288', delDomain: 'lqr-d.openx.net'} },
        // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
        { bidder: 'sovrn', params:{ tagid: 976815 } },
        { bidder: 'sovrn', params:{ tagid: 976816 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152848'} },
        { bidder: 'triplelift', params: { inventoryCode: 'Healthy-motherCOM_desktop_NEXT_RightColumnHalfPage1_Prebid' } },
            { bidder: 'sharethrough', params: {pkey: 'M8VaAKXHUdCPoc9Bl01taVih'}},
            { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116582 } },
            { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
            { bidder: 'taboola', params: { tagId: "NEXT_RightColumnHalfPage1", publisherId: '1493285'} },
            { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
            { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } }   
          ]
      
    },
    {
      "condition": window.screen.width > 812 && !checkUtmSourceStartsWith('yahoo_') && !checkUtmSourceStartsWith('fb-'),
      "id": "LeftColumn",
      "name": "NEXT_LeftColumnHalfPage",
      "path": `/76730613/NEXT_LeftColumnHalfPage`,
      "sizes": window.screen.width > 1440 ? [[300, 600], [160, 600], [300, 250]] : [[160, 600]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'LeftColumn', environment:'desktop', placement: 'NEXT_LeftColumnHalfPage' } },
        { bidder: 'adform', params: { mid: 1197191 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: {placementId: '23225125'} },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786141, size:[160, 600]} },
        //  ////{ bidder: 'ix', params: {siteId: 674795, size:[300, 600]} }, // adding this bid outside (search for: adding larger IX ads here)
        //{ bidder: 'justpremium', params: {zone: 134836, allow: ["sa"]}, labelAll:['notjp'] },
        { bidder: 'onemobile',params: {dcn: '8a9690e4017c7cc7c14bcc37ac490023',pos: '8a9699c2017c7cc7bc2bccc7ee4c007c',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: {unit: '556539294', delDomain: 'lqr-d.openx.net'} },
        // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
        { bidder: 'sovrn', params:{ tagid: 976817 } },
        { bidder: 'sovrn', params:{ tagid: 976818 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152848'} },
        { bidder: 'triplelift', params: { inventoryCode: 'Healthy-motherCOM_desktop_NEXT_LeftColumn_Prebid' } },
            { bidder: 'sharethrough', params: {pkey: 'SZ3nozrwkeF6UFsYtkodXGez'} },
            { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116578 } },
            { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },      
            { bidder: 'taboola', params: { tagId: "NEXT_LeftColumnHalfPage", publisherId: '1493285'} },
            { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
            { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } }
          ],
    },
  ];
}
