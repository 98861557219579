import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  const abtest = window.localStorage.getItem("abtest");
  console.log("|||", abtest);

  // Define Ad unit names
  // Original
  let RightColumnUnitName = "NEXT_RightColumnHalfPage1";
  let LeftColumnUnitName = "NEXT_LeftColumnHalfPage";
  let MPUCenterRightUnitName = "NEXT_MPUCenterRight";
  let MPUTopRightUnitName = "NEXT_MPUTopRight";
  let LeaderBelowTitleUnitName = "NEXT_Leader_BellowTitle";
  let StickyBannerUnitName = "NEXT_StickyBannerDesktop";
  let MPUBelowNextUnitName = "NEXT_MPUBelowNext";
  if (window.screen.width < 812) {
    StickyBannerUnitName = "NEXT_StickyBannerMobile";
  }

  // Domain/device Specific
  if (abtest === "ab.thm.com.b") {
    RightColumnUnitName = "DESK_NEXT_RightColumn";
    LeftColumnUnitName = "DESK_NEXT_LeftColumn";
    MPUCenterRightUnitName = "DESK_NEXT_MPUCenterRight";
    MPUTopRightUnitName = "MOB_NEXT_MPUTopRight";
    LeaderBelowTitleUnitName = "DESK_NEXT_LeaderBelowTitle";
    StickyBannerUnitName = "DESK_NEXT_StickyBannerDesktop";
    MPUBelowNextUnitName = "DESK_NEXT_MPUBelowNext";
    if (window.screen.width < 812) {
      MPUCenterRightUnitName = "MOB_NEXT_MPUCenterRight";
      LeaderBelowTitleUnitName = "MOB_NEXT_LeaderBelowTitle";
      StickyBannerUnitName = "MOB_NEXT_StickyBanner";
      MPUBelowNextUnitName = "MOB_NEXT_MPUBelowNext";
    }
  }

  // Define Ad Unit Pathways
  // Original
  let RightColumnPathway = "NEXT_RightColumnHalfPage1";
  let LeftColumnPathway = "NEXT_LeftColumnHalfPage";
  let MPUCenterRightPathway = "NEXT_MPUCenterRight";
  let MPUTopRightPathway = "NEXT_MPUTopRight";
  let LeaderBelowTitlePathway = "NEXT_Leader_BellowTitle";
  let StickyBannerPathway = "NEXT_StickyBannerDesktop";
  let MPUBelowNextPathway = "NEXT_MPUBelowNext";
  if (window.screen.width < 812) {
    StickyBannerPathway = "NEXT_StickyBannerMobile";
  }

  // Domain/device Specific
  if (abtest === "ab.thm.com.b") {
    RightColumnPathway = "THM/DESK_NEXT_RightColumn";
    LeftColumnPathway = "THM/DESK_NEXT_LeftColumn";
    MPUCenterRightPathway = "THM/DESK_NEXT_MPUCenterRight";
    MPUTopRightPathway = "THM/DESK_NEXT_MPUTopRight";
    LeaderBelowTitlePathway = "THM/DESK_NEXT_LeaderBelowTitle";
    StickyBannerPathway = "THM/DESK_NEXT_StickyBannerDesktop";
    MPUBelowNextPathway = "THM/DESK_NEXT_MPUBelowNext";
    if (window.screen.width < 812) {
      MPUTopRightPathway = "THM/MOB_NEXT_MPUTopRight";
      MPUCenterRightPathway = "THM/MOB_NEXT_MPUCenterRight";
      LeaderBelowTitlePathway = "THM/MOB_NEXT_LeaderBelowTitle";
      StickyBannerPathway = "THM/MOB_NEXT_StickyBannerMobile";
      MPUBelowNextPathway = "THM/MOB_NEXT_MPUBelowNext";
    }
  }

  // prettier-ignore
  let adUnits = [
    {
      "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_') && !checkUtmSourceStartsWith('fb-'),
      "id": "LeaderBelowTitle",
      "name": LeaderBelowTitleUnitName,
      "path": `/76730613/${LeaderBelowTitlePathway}`,
      "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[728, 90]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'LeaderBelowTitle', environment:'desktop', placement:`${LeaderBelowTitlePathway}` } },
        { bidder: 'adform', params: { mid: 1197163 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: { placementId: '23225124' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786140, size: [728,90] } },
        ////{ bidder: 'justpremium', params: {zone: 117362}, labelAll:['notjp'] },
        { bidder: 'openx', params: { unit: '556539284', delDomain: 'lqr-d.openx.net'} },
        { bidder: 'onemobile',params: {dcn: '8a9690e4017c7cc7c14bcc37ac490023',pos: '8a9694cb017c7cc7c69accc6a375007f',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        ////{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_Leader_bellowTitle' } },
        { bidder: 'sovrn', params:{ tagid: 976823 } },
        { bidder: 'teads', params: { pageId: '139293', placementId: '152848'} },
        { bidder: 'triplelift', params: { inventoryCode: 'Healthy-motherCOM_desktop_NEXT_Leader_bellowTitle_Prebid' } },
          { bidder: 'sharethrough', params: {pkey: 'dZKTPCl55zGgiMUVi4V8bqqo'} },
          { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116575 } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: LeaderBelowTitleUnitName, publisherId: '1493285'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } }
      ],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'LeaderBelowTitle', environment:'mobile', placement:`${LeaderBelowTitlePathway}` } },
        { bidder:  'adform', params: { mid: 1197168 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: { placementId: '23225177' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786153, size: [300,250] } },
        { bidder: 'ix', params: {siteId: 786153, size: [336,280] } },
        // ////{ bidder: 'justpremium', params: {zone: 117362}, labelAll:['notjp'] },
        { bidder: 'onemobile',params: {dcn: '8a9690e4017c7cc7c14bcc358e650021',pos: '8a9690e4017c7cc7c14bccb6f5650079',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '556539289', delDomain: 'lqr-d.openx.net'} },
        // // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_Leader_bellowTitle' } },
        { bidder: 'sovrn', params:{ tagid: 976842 } },
        { bidder: 'sovrn', params:{ tagid: 976843 } },
        { bidder: 'sovrn', params:{ tagid: 976844 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152850'} },
        { bidder: 'triplelift', params: {inventoryCode: 'Healthy-motherCOM_mobile_NEXT_Leader_belowTitle_Prebid'} },
        { bidder: 'sharethrough', params: {pkey: 'e6aPw83qIhTIWTGAwCLaHCKX'} },
        { bidder: 'sharethrough', params: {pkey: 'HBkLoYVHf3tOZqf5DxrRR3QP'} },
        { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116595 } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },      
        { bidder: 'taboola', params: { tagId: LeaderBelowTitleUnitName, publisherId: '1493285'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } }
      ]
    },
    {
      "condition": window.screen.width < 812,
      "id": "MPUTopRight",
      "name": MPUTopRightUnitName,
      "path": `/76730613/${MPUTopRightPathway}`,
      "sizes": [[300, 250],[336,280],[320,100]],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'MPUTopRight', environment:'mobile', placement: 'NEXT_MPUTopRight' } },
        { bidder: 'adform', params: { mid: 1197170 }, labelAll:['notjp'] },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix',  params: { siteId: 786155, size: [300, 250] } },
        { bidder: 'ix',  params: { siteId: 786155, size: [336, 280] } },
        { bidder: 'onemobile', params: { dcn:'8a9690e4017c7cc7c14bcc358e650021', pos:'8a9690e4017c7cc7c14bccc02030007a',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '556539291', delDomain: 'lqr-d.openx.net' } },
        { bidder: 'sovrn', params:{ tagid: 976847 } },
        { bidder: 'sovrn', params:{ tagid: 976848 } },
        { bidder: 'sovrn', params:{ tagid: 976849 } },
        { bidder: 'teads', params: { pageId: '139293', placementId: '152851'} },
        { bidder: 'triplelift', params: {inventoryCode: 'Healthy-motherCOM_mobile_NEXT_MPUTopRight_Prebid'} },
        { bidder: 'sharethrough', params: {pkey: 'MFFVHRHETtQJcwpE3X8pJjJu'}},
        { bidder: 'sharethrough', params: {pkey: 'DywyEPUnhopqYnzErcZuKLXg'}},
        { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116599 } },
        { bidder: 'onemobile', params: { pos: '8a9690e4017c7cc7c14bccc02030007a', dcn: '8a9690e4017c7cc7c14bcc358e650021'} },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: MPUTopRightUnitName, publisherId: '1493285'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } }
      ]
    },
    {
      "condition": window.screen.width > 812 && !checkUtmSourceStartsWith('fb-'),
      "id": "RightColumn",
      "name": RightColumnUnitName,
      "path": `/76730613/${RightColumnPathway}`,
      "sizes": window.screen.width > 1440 ? [[300, 600], [160, 600], [300, 250]] : [[160, 600]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'RightColumn', environment:'desktop', placement: 'NEXT_RightColumnHalfPage1' } },
        { bidder: 'adform', params: { mid: 1197185 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: {placementId: '23225151'} },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786144, size:[160, 600]} },
        ////{ bidder: 'justpremium', params: {zone: 117362}, labelAll:['notjp'] },
        { bidder: 'onemobile',params: {dcn: '8a9690e4017c7cc7c14bcc37ac490023',pos: '8a9690e4017c7cc7c14bccc78b0e0080',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: {unit: '556539288', delDomain: 'lqr-d.openx.net'} },
        // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
        { bidder: 'sovrn', params:{ tagid: 976815 } },
        { bidder: 'sovrn', params:{ tagid: 976816 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152848'} },
        { bidder: 'triplelift', params: { inventoryCode: 'Healthy-motherCOM_desktop_NEXT_RightColumnHalfPage1_Prebid' } },
            { bidder: 'sharethrough', params: {pkey: 'M8VaAKXHUdCPoc9Bl01taVih'}},
            { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116582 } },
            { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },      
            { bidder: 'taboola', params: { tagId: RightColumnUnitName, publisherId: '1493285'} },
            { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
            { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } }
          ]
      
    },
    {
      "condition": window.screen.width > 812 && !checkUtmSourceStartsWith('yahoo_') && !checkUtmSourceStartsWith('fb-'),
      "id": "LeftColumn",
      "name": LeftColumnUnitName,
      "path": `/76730613/${LeftColumnPathway}`,
      "sizes": window.screen.width > 1440 ? [[300, 600], [160, 600], [300, 250]] : [[160, 600]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'LeftColumn', environment:'desktop', placement: 'NEXT_LeftColumnHalfPage' } },
        { bidder: 'adform', params: { mid: 1197191 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: {placementId: '23225125'} },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786141, size:[160, 600]} },
        //  ////{ bidder: 'ix', params: {siteId: 674795, size:[300, 600]} }, // adding this bid outside (search for: adding larger IX ads here)
        //{ bidder: 'justpremium', params: {zone: 134836, allow: ["sa"]}, labelAll:['notjp'] },
        { bidder: 'onemobile',params: {dcn: '8a9690e4017c7cc7c14bcc37ac490023',pos: '8a9699c2017c7cc7bc2bccc7ee4c007c',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: {unit: '556539294', delDomain: 'lqr-d.openx.net'} },
        // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
        { bidder: 'sovrn', params:{ tagid: 976817 } },
        { bidder: 'sovrn', params:{ tagid: 976818 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152848'} },
        { bidder: 'triplelift', params: { inventoryCode: 'Healthy-motherCOM_desktop_NEXT_LeftColumn_Prebid' } },
            { bidder: 'sharethrough', params: {pkey: 'SZ3nozrwkeF6UFsYtkodXGez'} },
            { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116578 } },
            { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
            { bidder: 'taboola', params: { tagId: LeftColumnUnitName, publisherId: '1493285'} },
            { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
            { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } }      
          ],
    },
    {
      "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      "id": "StickyBanner",
      "name": StickyBannerUnitName,
      "path": `/76730613/${StickyBannerPathway}`,
      "sizes": window.screen.width < 812 ? [[320, 50],[300,50],[360,50],[320,100],[300,100],[360,100], [1,1]] : [[728, 90]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'StickyBanner', environment:'desktop', placement:`${StickyBannerPathway}` } },
        { bidder: 'adform', params: { mid: 1197190 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: {placementId: '23225152'} },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786145, size:[728, 90]} },
        ////{ bidder: 'justpremium', params: {zone: 117362}, labelAll:['notjp'] },
        { bidder: 'onemobile',params: {dcn: '8a9690e4017c7cc7c14bcc37ac490023',pos: '8a9694cb017c7cc7c69accc9bd3d0080',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: {unit: '556539297', delDomain: 'lqr-d.openx.net'} },
        // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
        { bidder: 'sovrn', params:{ tagid: 976824 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152848'} },
        { bidder: 'triplelift', params: { inventoryCode: 'Healthy-motherCOM_desktop_NEXT_StickyBanner_Prebid' } },
            { bidder: 'sharethrough', params: {pkey: 'p0c7SlJlH2A8Bs9c9RnCiCox'} },
            { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116583 } },
            { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
            { bidder: 'taboola', params: { tagId: StickyBannerUnitName, publisherId: '1493285'} },
            { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
            { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } }      
      ],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'StickyBanner', environment:'mobile', placement:`${StickyBannerPathway}` } },
        { bidder: 'adform', params: { mid: 1197171 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: { placementId: '23225155' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786156, size: [320,50]} },
        { bidder: 'ix', params: {siteId: 786156, size: [300,50]} },
        { bidder: 'ix', params: {siteId: 786156, size: [360,50]} },
        { bidder: 'ix', params: {siteId: 786156, size: [320,100]} },
        { bidder: 'ix', params: {siteId: 786156, size: [300,100]} },
        { bidder: 'ix', params: {siteId: 786156, size: [360,100]} },
        //{ bidder: 'justpremium', params: {zone: 134836, allow: ["ms"]}, labelAll:['notjp'] },
        { bidder: 'onemobile',params: {dcn: '8a9690e4017c7cc7c14bcc358e650021',pos: '8a9694cb017c7cc7c69accc20002007d',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '556539292', delDomain: 'lqr-d.openx.net' } },
        // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_StickyBannerMobile' } },
        { bidder: 'sovrn', params:{ tagid: 976853 } },
        { bidder: 'sovrn', params:{ tagid: 976854 } },
        { bidder: 'sovrn', params:{ tagid: 976855 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152848'} },
        { bidder: 'triplelift', params: {inventoryCode: 'Healthy-motherCOM_mobile_NEXT_StickyBanner_Prebid'} },
          { bidder: 'sharethrough', params: {pkey: 'lxkxcT02YXOV1ptrJRDzP4Fj'}},
          { bidder: 'sharethrough', params: {pkey: '0BaDg3zPgXAZBsFQ60AcuvFT'}},
          { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116600 } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },  
          { bidder: 'taboola', params: { tagId: StickyBannerUnitName, publisherId: '1493285'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } }    
        ]
    },
    {
      "id": "MPUBelowNext",
      "name":  MPUBelowNextUnitName,
      "path": `/76730613/${MPUBelowNextPathway}`,
      "sizes": window.screen.width < 812 ? [[300, 250],[336,280], [1,1] ] : [[300, 250],[336,280]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'MPUBelowNext', environment:'desktop', placement:`${MPUBelowNextPathway}` } },
        { bidder: 'adform', params: { mid: 1197164 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: {placementId: '23225126'} },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786142, size:[300, 250]} },
        { bidder: 'ix', params: {siteId: 786142, size:[336, 280]} },
        ////{ bidder: 'justpremium', params: {zone: 117362}, labelAll:['notjp'] },
        { bidder: 'onemobile',params: {dcn: '8a9690e4017c7cc7c14bcc37ac490023',pos: '8a9694cb017c7cc7c69accca33f90081',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: {unit: '556539285', delDomain: 'lqr-d.openx.net'} },
        // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
        { bidder: 'sovrn', params:{ tagid: 976821 } },
        { bidder: 'sovrn', params:{ tagid: 976822 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152852'} },
        { bidder: 'triplelift', params: { inventoryCode: 'Healthy-motherCOM_desktop_NEXT_MPUBelowNext_Prebid' } },
          { bidder: 'sharethrough', params: {pkey: 'wSw64DXXx9g4jhM9UksUH28g'} },
          { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116579 } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: MPUBelowNextUnitName, publisherId: '1493285'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } }
      ],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'MPUBelowNext', environment:'mobile', placement: `${MPUBelowNextPathway}` } },
        { bidder: 'adform', params: { mid: 1197167 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: {placementId: '23225178'} },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 786152, size:[300, 250]} },
        { bidder: 'ix', params: {siteId: 786152, size:[336, 280]} },
        // ////{ bidder: 'justpremium', params: {zone: 117362}, labelAll:['notjp'] },
        { bidder: 'onemobile',params: {dcn: '8a9690e4017c7cc7c14bcc358e650021',pos: '8a9690e4017c7cc7c14bccc312c6007b',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: {unit: '556539283', delDomain: 'lqr-d.openx.net'} },
        // // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
        { bidder: 'sovrn', params:{ tagid: 976845 } },
        { bidder: 'sovrn', params:{ tagid: 976846 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152852'} },
        { bidder: 'triplelift', params: { inventoryCode: 'Healthy-motherCOM_mobile_NEXT_MPUBelowNext_Prebid' } },
        { bidder: 'sharethrough', params: {pkey: '7H74ptfYjvCW1IHlgzYDMysn'}},
        { bidder: 'sharethrough', params: {pkey: 'JVI4JsD917v9Pq75Aho6GI5m'}},
        { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116596 } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} }, 
        { bidder: 'taboola', params: { tagId: MPUBelowNextUnitName, publisherId: '1493285'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } }
      ]
    },
    {
      "id": "MPUCenterRight",
      "name": MPUCenterRightUnitName,
      "path": `/76730613/${MPUCenterRightPathway}`,
      "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100],[1,1]] : [[300, 250],[336,280],[1,1]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'MPUCenterRight', environment:'desktop', placement:`${MPUCenterRightPathway}` } },
        { bidder:  'adform', params: { mid: 1197165 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: { placementId: '23225150' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix',  params: { siteId: 786143, size: [300, 250] } },
        { bidder: 'ix',  params: { siteId: 786143, size: [336, 280] } },
     //    //{ bidder: 'justpremium', params: {zone: 117362}, labelAll:['notjp'] },
        { bidder: 'onemobile',params: {dcn: '8a9690e4017c7cc7c14bcc37ac490023',pos: '8a9690e4017c7cc7c14bccc725ee007f',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '556539286', delDomain: 'lqr-d.openx.net' } },
        ////{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUCenterRight' } },
        { bidder: 'sovrn', params:{ tagid: 976819 } },
        { bidder: 'sovrn', params:{ tagid: 976820 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152852'} },
        { bidder: 'triplelift', params: { inventoryCode: 'Healthy-motherCOM_desktop_NEXT_MPUCenterRight_Prebid'} },
          { bidder: 'sharethrough', params: {pkey: 'A6xf0Dej1U0dOEdMjh4fa0xy'} },
          { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116580 } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: MPUCenterRightUnitName, publisherId: '1493285'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } }
      ],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'thehealthy-mother-com', adUnitElementId: 'MPUCenterRight', environment:'mobile', placement:`${MPUCenterRightPathway}` } },
        { bidder: 'adform', params: { mid: 1197169 }, labelAll:['notjp'] },
        //{ bidder: 'appnexus', params: { placementId: '23225179' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix',  params: { siteId: 786154, size: [300, 250] } },
        { bidder: 'ix',  params: { siteId: 786154, size: [336, 280] } },
        //{ bidder: 'justpremium', params: {zone: 134836, allow: ["is"]}, labelAll:['notjp'] },
        { bidder: 'onemobile' ,params: { dcn:'8a9690e4017c7cc7c14bcc358e650021', pos:'8a9694cb017c7cc7c69accbce568007c',ext: {}} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '556539290', delDomain: 'lqr-d.openx.net' } },
        // // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_MPUCenterRight' } },
        { bidder: 'sovrn', params:{ tagid: 976850 } },
        { bidder: 'sovrn', params:{ tagid: 976851 } },
        { bidder: 'sovrn', params:{ tagid: 976852 } },
        { bidder: 'teads', params: {pageId: '139293', placementId: '152852'} },
        { bidder: 'triplelift', params: {inventoryCode: 'Healthy-motherCOM_mobile_NEXT_MPUCenterRight_Prebid'} },
        { bidder: 'sharethrough', params: {pkey: 'aT3sdjzrx40wEwg2FYkNYBf8'}},
        { bidder: 'sharethrough', params: {pkey: 'QjlO6NP2us1isXeL887s0jVe'} },
        //{ bidder: 'ogury', params: { assetKey: 'OGY-1F23106BD0A9', adUnitId: 'c817d7d0-17e3-013b-0c1f-073529a97c48', skipSizeCheck: true } },
        { bidder: 'smartadserver', params: { siteId: 536186, pageId: 1662536, formatId: 116597 } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: MPUCenterRightUnitName, publisherId: '1493285'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847491' } }
      ]
    }
  ];

  /**
   * Finds left and right columns, pushes the missing bidders
   * and pushes correct bidders to the adunit
   */
  // prettier-ignore
  if (window.screen.width > 1440) {
      let rcMissingIX = adUnits.find(ad => ad.id === 'RightColumn');
      let lcMissingIX = adUnits.find(ad => ad.id === 'LeftColumn');
      // Right
      rcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 786144, size:[300, 600]} });
      rcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 786144, size:[300, 250]} });
      rcMissingIX.bidders.push({ bidder: 'sovrn', params:{ tagid: 1103050 } });
      //Left
      lcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 786141, size:[300, 600]} });
      lcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 786141, size:[300, 250]} });
      lcMissingIX.bidders.push({ bidder: 'sharethrough', params: {pkey: '0dc5nLemJN1vGhma4KZuNLL0'}});
      lcMissingIX.bidders.push({ bidder: 'sovrn', params:{ tagid: 1103052 } });
    }

  return adUnits;
}
